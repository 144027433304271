<template>
  <div class="view-token text-center">
    <b-spinner variant="primary" />
  </div>
</template>

<script>
import { setToken } from "@/setup/api";

export default {
  mounted() {
    setToken(this.$route.query.token);
    this.$store.commit("user/setLogged", true);
    setTimeout(() => {
      this.$router.replace({ name: "home" });
    }, 300);
  }
};
</script>
